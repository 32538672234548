#notification-editor {
  background-color: white;
  border-radius: 0.5rem;

  .ProseMirror {
    min-height: 14rem;
    height: auto;

    margin-left: 16px;

    background: white;

    > * + * {
      margin-left: 0.75rem;
    }
  }

  .ProseMirror:focus {
    outline: none;
  }
}

.toolbar {
  display: flex;

  width: 100%;

  background-color: var(--gray-900);

  display: inline-flex;
  isolation: isolate;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;

  border-left: 0.1rem;
  border-left-color: black;

  --shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --ring-offset-shadow: 0 0 #0000;
  --ring-shadow: 0 0 #0000;

  box-shadow: var(--ring-offset-shadow, 0 0 #0000),
    var(--ring-shadow, 0 0 #0000), var(--shadow);

  svg {
    height: 1.5rem;
    width: 1.5rem;

    stroke: var(--gray-100);
  }

  > *:first-child {
    margin-left: 4px;
  }
}

.datepicker-container {
  margin-left: 1rem;

  display: flex;
  align-items: center;

  color: var(--gray-100);
}

.datepicker {
  padding: 4px;
  font-size: 16px;

  color: var(--gray-100);
  background-color: var(--gray-900);

  outline: none;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.datepicker::-webkit-calendar-picker-indicator {
  display: none;
}

.submit-row {
  display: flex;
  justify-content: space-evenly;

  background-color: var(--gray-900);

  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
