.contentContainer {
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.kladblokWrapper {
    border-radius: var(--default-border-radius);
    background-color: var(--default-page-background-color);
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    height: 750px;
    overflow: overlay;

    * {
        overflow-anchor: none;
    }

    #anchor {
        overflow-anchor: auto;
        height: 1px;
    }

    .inhoud {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 2px;
        font-size: 18px;

        .date {
            font-size: 14px;
            color: var(--default-text-color);
            font-style: italic;
        }

        .gebruiker {
            color: var(--default-primary-color);
            font-size: 14px;
        }
    }

    .regel {
        background-color: var(--default-background-color);
        gap: 20px;
        align-items: stretch;
        flex-direction: row;
        border-radius: var(--default-border-radius);
        padding: 10px 20px;
        color: var(--default);
        display: inline-flex;
        width: max-content;

        &.own {
            background-color: #d2f8d2;
            justify-content: flex-end;
            margin-left: auto;
            flex-direction: row-reverse;

            .disciplineIndicator {
                box-shadow: var(--default-box-shadow);
            }
        }
    }
}

.multiCheckboxWrapper {
    display: flex;
    gap: 30px;
    justify-content: start;
    align-items: center;
}

.explanation {
    color: var(--default-text-color);
    font-style: italic;
}

.inputWrapper {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--default-background-color);
    border-radius: var(--default-border-radius);

    .input {
        width: 100%;
    }

    button {
        width: 100px;
    }
}