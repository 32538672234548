.button {
  width: 42px;
  height: 42px;
  padding: 0;
  color: white;
  border-radius: var(--default-border-radius);
  display: block;

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  &.hasTitle {
    width: auto;
    display: flex;
    gap: 10px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.editButton,
.cancelButton {
  composes: button;
  background-color: orange;
}

.removeButton {
  composes: button;
  background-color: var(--default-primary-color);
}

.meldkamerButton {
  composes: button;
  background-color: var(--default-confirm-color);
}

.addButton,
.submitButton,
.sendButton,
.reactivateButton {
  composes: button;
  background-color: var(--default-confirm-color);
}