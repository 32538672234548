.dropdown.open {
  overflow: inherit;
  .options {
    height: auto;
    max-height: 600px;
    overflow-y: scroll;
  }
}

.dropdown-wrapper {
  width: 100%;
  & > label {
    float: left;
    width: 100%;
    color: var(--default-font-color);
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.dropdown {
  height: 50px;
  background: var(--background-color-2);
  border-radius: 8px;
  opacity: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  color: var(--default-font-color);
  position: relative;
  width: 100%;
  padding: 20px;

  .dropdown-label {
    width: 100%;
    display: flex;
    justify-content: space-between;

    float: left;
    width: 100%;
    font: var(--input-value-font);
    font-weight: normal;
    margin-bottom: 0px;
  }

  .options {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
    position: absolute;
    left: 0;
    height: 0;
    font-weight: normal;
    overflow: hidden;
    top: 54px;
    background-color: var(--background-color-2);
    box-sizing: border-box;
    z-index: 100;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .categorized-values {
      height: 54px;
      overflow: hidden;
      text-indent: 20px;
      border-top: 2px solid var(--accent-color);

      .category-label {
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font: var(--input-value-font);
      }

      width: 100%;

      .option {
        background-color: var(--background-color-0);
        padding-left: 20px;
        margin-top: 10px;
        border-radius: 8px;
        margin-bottom: 5px;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }
  }
}
