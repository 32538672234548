.header {
  background-color: var(--default-background-color);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-content {
  max-width: var(--main-width);

  margin-left: auto;
  margin-right: auto;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  h1 {
    color: var(--gray-100);
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    letter-spacing: -0.025em;
  }
}

main {
  background-color: var(--default-page-background-color);
  min-height: 100vh;
}

.main {
  max-width: var(--main-width);
  display: flex;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.page-content {
  border-radius: 0.5rem;
  border-width: 4px;
  width: 100%;
}