.contentContainer {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.records {
  display: block;
  flex-wrap: wrap;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;

  .record {
    width: 100%;
    height: 90px;
    display: grid;
    grid-template-columns: 5px 1fr 1fr fit-content(20%);
    justify-content: flex-start;
    gap: 40px;
    box-sizing: border-box;
    padding: 0px 20px;
    align-items: center;

    .locationWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 5px;
      gap: 5px;

      .brwMelding {
        color: black;
        font-style: italic;
      }

      .location {
        display: flex;
        gap: 5px;
      }
    }

    &.gesloten {
      opacity: 0.5;
    }

    .tag {
      background-color: var(--default-confirm-color);
      color: var(--default-background-color);
      font-weight: bold;
      border-radius: var(--default-border-radius);
      height: 25px;
      display: block;
      padding: 0 10px;
      float: left;
      margin-right: 20px;

      &.gesloten {
        background-color: var(--default-primary-color);
      }
    }

    &:nth-child(odd) {
      background-color: var(--default-page-background-color);
    }


    .description {
      color: var(--default-primary-color);
      display: flex;
      gap: 20px;
      align-items: center;

      .incidentNr {
        font-weight: bold;
        font-size: 17px;
      }
    }

    .actions {
      display: flex;
      margin-left: auto;
      gap: 10px;
      width: 150px;
    }

    .vehicleCodes {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;

      .vehicleCode {
        background: var(--default-background-color);
        border: 1px solid var(--default-page-background-color);
        border-radius: 5px;
        color: var(--default-primary-color);
        display: block;
        font-weight: bold;
        font-size: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 32px;
        padding: 5px;
        box-sizing: border-box;
      }
    }
  }
}

.loadMore {
  background-color: var(--default-primary-color);
  color: var(--default-background-color);
}