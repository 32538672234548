.animation {
  width: 1020px;
  height: 160px;
  padding-top: 70px;
  border-radius: 0.5rem;
  background-color: var(--gray-400);
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
}

.system-container {
  background-color: var(--green-100);
  border: solid var(--green-400) 5px;
  color: var(--green-900);
  width: 150px;
  height: 100px;
  float: left;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
}
.system-container.error {
  border-color: var(--red-400);
  background-color: var(--red-100);
  color: var(--red-900);
}

.system-container.warning {
  border-color: var(--orange-400);
  background-color: var(--orange-100);
  color: var(--orange-900);
}

.container {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 105px;
  width: 635px;
  float: left;
}

.warning > .path {
  display: flex;
  justify-content: space-around;
  animation: flow-path 5s linear infinite;
  width: 100%;

  &::before {
    background: orange;
    border-radius: 50%;
    content: "";
    height: 1rem;
    width: 1rem;
  }
}

.path {
  display: flex;
  justify-content: space-around;
  animation: flow-path 5s linear infinite;
  width: 100%;

  &::before {
    background: #90ee90;
    border-radius: 50%;
    content: "";
    height: 1rem;
    width: 1rem;
  }
}

.fault {
  //display: flex;
  justify-content: space-around;
  //animation: flow-path 5s linear infinite;
  width: 100%;
  color: red;
  font-size: 23px;

  &::before {
    //background: #90EE90;
    border-radius: 50%;
    content: "";
    height: 1rem;
    width: 1rem;
  }
}

@keyframes chunk-top {
  0%,
  100% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-80deg);
  }
}

@keyframes chunk-bottom {
  0%,
  100% {
    transform: rotate(-40deg);
  }
  50% {
    transform: rotate(0deg);
  }
}

@keyframes flow-path {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(50%);
  }
}
