.log-overview {
  display: flex;
  justify-content: space-evenly;
  margin-left: 2rem;
  margin-right: 2rem;

  border-radius: 0.5rem;
  max-width: var(--main-width);
  background-color: var(--gray-100);
}

.table-container > table {
  font-family: arial, sans-serif;
  border-collapse: separate;

  font-size: 15px;

  thead {
    background-color: var(--gray-100);
    border-bottom-width: 1px;
  }

  td,
  th {
    text-align: left;
    padding: 5px;
    text-align: center;
  }

  .success {
    background-color: var(--green-100);
    color: var(--green-900);

    td {
      border: solid var(--green-400) 2px;
      border-radius: 5px;
      padding-left: 4px;
    }
  }

  .failure {
    background-color: var(--red-100);
    color: var(--red-900);

    td {
      border: solid var(--red-400) 2px;
      border-radius: 5px;
      padding-left: 4px;
    }
  }
}
