.notification {
  border-radius: 0.5rem;
  background-color: white;

  margin: 1rem;

  display: flex;
  flex-direction: column;

  button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.edit-mode {
  flex: 1 0 75%;

  .submit-row {
    border-radius: 0;
  }
}

.view-mode {
  flex: 1 0 33.3333%;
}
