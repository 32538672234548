@import "/src/app/variables.scss";

.checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled {
    opacity: 0.5;
}

.checkedIcon,
.uncheckedIcon {
    width: 30px;
    height: 30px;
    color: var(--default-primary-color);
}