.text-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  label {
    color: var(--default-font-color);
    font: var(--input-label-font);
    font-weight: 700;
  }

  input[type="text"] {
    background: var(--background-color-2);
    border: 0;
    border-radius: 8px;
    color: var(--default-font-color);
    font: var(--input-value-font);
    font-weight: 400;
    height: 50px;
    padding: 0;
    padding-left: 20px;
  }
}
