.lds-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80px;
  height: 80px;
  margin: 0 auto;

  &.white {
    div {
      background-color: white;
    }
  }

  &.small {
    width: 35px;
    height: 35px;

    div {
      width: 10px;
      height: 10px;
    }
  }
}

.lds-grid div {
  border-radius: 50%;
  width: 19px;
  height: 19px;
  background: var(--default-primary-color);
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}