.defaultButton {
    background-color: var(--default-background-color);
    color: var(--default-primary-color);
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.loadMore,
.cancelButton {
    composes: defaultButton;
    background-color: var(--default-primary-color);
    color: var(--default-background-color);
}

.confirmButton {
    composes: defaultButton;
    background-color: var(--default-confirm-color);
    color: var(--default-background-color);

}