body {
  --main-width: 100rem;
  //grays
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;

  //greens
  --green-50: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;
  //oranges
  --orange-50: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  //reds
  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;

  --background-color-0: #353f48;
  --background-color-1: #26333d;
  --background-color-2: #1e2a33;
  --default-font-color: #fff;
  --accent-color: #7088ba;
  --input-value-font: normal normal 600 13px system-ui, -apple-system, system-ui,
    "Helvetica Neue", Helvetica, Arial, sans-serif;

  --button-height: 50px;
  --default-border-radius: 8px;

  --default-background-color: #fdfdfd;
  --default-page-background-color: #f3f3f3;
  --default-primary-color: #d0021b;
  --default-text-color: #707172;
  --default-warning-color: #fc0;
  --default-confirm-color: #46A54E;
  --default-box-shadow: #00000029 0px 1px 4px;
}