.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba($color: #a5b1b8, $alpha: 0.5);

    .dialog {
        background-color: white;
        border-radius: var(--default-border-radius);
        box-shadow: var(--default-box-shadow);
        padding: 20px;

        .actions {
            display: flex;
            justify-content: space-around;
        }
    }
}