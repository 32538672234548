
.formWrapper{
  .header{
    display: grid;
    justify-content: space-between;
    padding: 20px 0;
    align-content: center;
    gap: 20px;
    align-items: center;
    grid-template-columns: repeat(3, minmax(0, 1fr)) 120px;

    font-weight: bold;
    color: var(--default-primary-color);
  }

  .add{
    display: flex;
    justify-content: flex-end;
    padding: 10px;

  }

  > div:nth-child(even){
    background-color: var(--default-page-background-color);

    input{
      background-color: var(--default-background-color);
    }
  }
}
