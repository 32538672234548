.text-area-wrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  label{
    color: var(--default-primary-color);
    font: var(--input-label-font);
    font-weight: 700;
  }

  textarea{
    background: var(--default-page-background-color);
    border: 0;
    border-radius: 8px;
    color: var(--default-text-color);
    font: var(--input-value-font);
    font-weight: 400;
    padding: 20px;
  }
}