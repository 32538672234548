.incident-overview > table {
  all: revert;
  border-spacing: unset;
  table-layout: auto;

  background-color: white;
  border-bottom-width: 1px;

  height: 512px;
  font-size: 15px;

  thead {
    background-color: var(--gray-900);
  }

  td,
  th {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--gray-900);
  }

  th {
    color: var(--gray-200);
  }

  tr:nth-child(even) {
    background-color: var(--gray-100);
  }
}

.incident-overview {
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  border-radius: 0.5rem;
  max-width: var(--main-width);
  background-color: var(--gray-100);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -110px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
