.eenheidGroupRow {
  display: grid;
  justify-content: space-between;
  padding: 5px 10px;
  align-content: center;
  gap: 20px;
  align-items: center;
  grid-template-columns: repeat(3, minmax(0, 1fr)) 120px;

  input {
    margin-left: 0 !important;
    font-size: 16px !important;
  }

  >div {
    display: inline-grid;

    >div {
      width: 90%;
    }
  }

  .editActions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .vehicleCodes {
    display: flex;
    flex-wrap: wrap;

    .vehicleCode {
      background: var(--default-background-color);
      border: 1px solid var(--default-page-background-color);
      border-radius: 5px;
      color: var(--default-primary-color);
      display: block;
      font-weight: bold;
      font-size: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 32px;
      padding: 5px;
      box-sizing: border-box;
    }
  }

  .flexWrapper {
    display: flex;
    gap: 20px;
  }
}
