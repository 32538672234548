.input-date-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  label {
    float: left;
    width: auto;
    color: var(--default-font-color);
    font-weight: bold;
    margin-bottom: 10px;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    background: var(--background-color-2);
    height: var(--button-height);
    border-radius: var(--default-border-radius);

    svg {
      color: var(--default-font-color);
      margin-right: 20px;
    }

    input {
      &:focus,
      &:active {
        outline: none;
      }

      padding: 0 20px;
      border: 0;
      float: right;
      color: var(--default-font-color);
      position: relative;
      font: var(--input-value-font);
      background: transparent;

      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    }
  }
}
