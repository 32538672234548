.notification-preview {
  margin-top: 1.25rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;

  flex-wrap: wrap;
}
