.add-incident-component-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: var(--gray-700);
  border-radius: var(--default-border-radius);
}

.horizontal-form-row {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 100%;
  gap: 10px;
}

.multi-checkbox-wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
}

.add-eenheden-button {
  margin-left: auto;
  margin-top: 10px;
}