.page-meldingen {
  display: flex;
  flex-direction: column;

  border-radius: 0.5rem;
  margin: 1.25rem;
  overflow-x: auto;

  max-width: calc(var(--main-width) / 2);
  width: 100%;
  box-sizing: border-box;

  .svg-button {
    border: none !important;
    background-color: transparent !important;
    outline: none;

    padding: 4px;
  }

  button.is-active {
    svg {
      background-color: var(--gray-600);
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.375rem;
    padding: 0.25rem;
  }
}
