@import "./_variables.scss";

.log-button {
  margin-top: 20px;
  width: 33%;
  margin-left: 34%;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  margin-top: -1px;
  margin-bottom: -5px;
  background-color: var(--default-page-background-color);
  height: 1px;
}

table {
  border-collapse: collapse;

  thead {
    tr {
      background-color: var(--default-background-color) !important;
      border: 2px solid var(--default-page-background-color);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  th {
    color: var(--default-primary-color);
    padding: 10px 20px;
    text-align: left;
  }

  tr {
    td {
      padding: 5px 20px;
      height: 50px;
    }

    &:nth-child(even) {
      background-color: var(--default-background-color);
    }

    &:nth-child(odd) {
      background-color: var(--default-page-background-color);
    }
  }
}

.content-container {
  background: var(--default-background-color);
  border-radius: var(--default-border-radius);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: var(--default-box-shadow);
  display: flex;
  flex-direction: column;
}

button {
  align-items: center;
  background-color: var(--gray-900);
  color: var(--gray-300);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

button[disabled] {
  background-color: var(--gray-700);
  cursor: default;
}

button:hover:not([disabled]),
button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

button:active:not([disabled]) {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.nav {
  background-color: var(--default-background-color);
  box-shadow: var(--default-box-shadow);
  z-index: 999;

  .nav-main {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-width: var(--main-width);

    @media (min-width: 1024px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media (min-width: 640px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .nav-contents {
    position: relative;
    display: flex;
    height: 4rem;
    align-items: center;
    justify-content: space-between;
  }

  .nav-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;

    @media (min-width: 640px) {
      display: none;
    }
  }

  .nav-mobile-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    padding: 0.5rem;
    color: var(--default-primary-color);

    &:hover {
      background-color: var(--gray-700);
      color: white;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 calc(2px) rgb(255 255 255);
    }

    span {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      display: block;
    }
  }

  .nav-mobile-menu {
    @media (min-width: 640px) {
      display: none;
    }
  }

  .nav-mobile-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }

  .nav-mobile-link {
    display: block;
    color: var(--gray-300);
    margin-top: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  .nav-items {
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    justify-content: center;

    @media (min-width: 640px) {
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  .logo {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    img {
      display: none;
      height: 2rem;
      width: auto;

      @media (min-width: 1024px) {
        display: block;
      }
    }
  }

  .nav-links {
    display: none;

    @media (min-width: 640px) {
      margin-left: 1.5rem;
      display: block;
    }
  }

  .nav-links-container {
    display: flex;
    gap: 10px;
    margin-left: 1rem;
  }

  .nav-link {
    color: var(--default-primary-color);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
  }

  .hover:hover {
    text-decoration: underline;
  }

  .active {
    background-color: var(--default-primary-color);
    color: white;
  }
}

a {
  text-decoration-line: none;
}

:root {
  --toastify-color-dark: #26333d !important;
  --toastify-font-family: "Inter", sans-serif !important;

  .Toastify__toast {
    border-radius: 8px !important;
  }
}