.notification-view {
  margin: 1rem;
  background-color: white;
  border-radius: 0.5rem;

  .ProseMirror {
    min-height: 14rem;
    height: auto;

    margin-left: 16px;

    background: white;

    > * + * {
      margin-left: 0.75rem;
    }

    border-radius: 0.5rem;
  }

  .ProseMirror:focus {
    outline: none;
  }
}
