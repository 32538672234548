.page-oefenincident {
  width: 90%;
}

input:focus, input:focus-visible {
  outline: 1px solid var(--default-primary-color);
}

.Toastify__toast-theme--dark {
  background: white;
  color: var(--default-text-color);
}

.submit-row {
  justify-content: end;
  background: transparent;
}

.submit {
  color: white;
  float: right;
}

.search-select__control--is-focused {
  border-color: var(--default-primary-color);
}

.search-select__multi-value__label {
  background: var(--default-background-color);
  color: var(--default-primary-color);
  font-weight: bold;
  line-height: 25px;
  padding-right: 10px;
}

.search-select__control {
  background: var(--default-page-background-color);
  color: var(--default-text-color);
  box-shadow: none;

  &:hover {
    border-color: var(--default-primary-color);
  }

  .search-select__placeholder {
    color: var();
    opacity: 0.8;
  }
}


label, .label {
  font-weight: 700;
  color: var(--default-primary-color);
}


form, .dynamic-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .add-button {
    float: right;
  }

  button {
    background: var(--default-primary-color);

    svg {
      color: white;
    }
  }

  .example {
    margin-top: -7px;
    font-style: italic;
  }
}

.input-date-wrapper {
  label {
    color: var(--default-primary-color);
  }

  .input-wrapper {
    background: var(--default-page-background-color);

    input, svg {
      color: var(--default-text-color);
    }
  }
}

.dropdown-wrapper {
  label {
    color: var(--default-primary-color);
  }

  .dropdown, .options {
    background: var(--default-page-background-color);
    color: var(--default-text-color);
  }

  .categorized-values {
    border-top: 2px solid var(--default-text-color);

    &:first-child {
      border-top: 0;
    }
  }
}

.text-input-wrapper {
  label {
    color: var(--default-primary-color);
  }

  input[type=text] {

    &[disabled] {
      opacity: 0.6;
    }

    background: var(--default-page-background-color);
    color: var(--default-text-color);
  }
}
